import React, { useEffect, useState } from 'react';

import { Box, Button, Headline, Spinner, Text } from '@sl-it-dev/sl-react';
import classNames from 'classnames';
import SVG from 'react-inlinesvg';
import { NavLink } from 'react-router-dom';

import BonusForm from '../components/bonus-form/BonusForm';
import OrderPositions from '../components/order-positions/OrderPositions';
import ProductAttributes from '../components/product-attributes/ProductAttributes';
import ProductCanvas from '../components/product-canvas/ProducCanvas';
import ProductDocuments from '../components/product-documents/ProductDouments';

import styles from '../app.module.scss';
import formStyles from '../components/bonus-form/bonusForm.module.scss';

import { faBadgeCheck } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import pleatsIcon from '../assets/svg/plissee_icon.svg';

const Info = ({ pid, product, loading }) => {
  const [showOrderSuccess, setShowOrderSuccess] = useState(false);
  const [showBonusFormButton, setShowBonusFormButton] = useState(false);

  useEffect(() => {
    const url = new URL(window.location);
    if ('1' === url.searchParams.get('orderSuccess')) {
      window.scrollTo({ top: 0 });
      setShowOrderSuccess(true);
    }
  }, []);

  return (
    <div>
      {showOrderSuccess && (
        <Text>
          <span className={formStyles.bonusFormSubmitted}>
            <FontAwesomeIcon icon={faBadgeCheck} /> Ihre Anfrage für ein unverbindliches Angebot
            wurde erfolgreich übermittelt.
          </span>
        </Text>
      )}
      <Box>
        <Headline h2>Herzlich Willkommen im Solarlux Serviceportal</Headline>
      </Box>
      <div className={`${styles.p1} ${styles.content}`}>
        <Text>
          Liebe Solarlux Kundin, lieber Solarlux Kunde,
          <br />
          <br />
          herzlich Willkommen im Solarlux Serviceportal, Ihrer persönlichen Plattform für Ihr
          Solarlux Produkt.
          <br />
          Wir freuen uns, dass Sie sich für Glaselemente von Solarlux entschieden haben und hoffen,
          Sie genießen diese bereits in vollen Zügen. Auf dieser Seite erhalten Sie alle
          wissenswerten Informationen rund um Ihr Produkt.
          <br />
          <br />
          Wir wünschen Ihnen viel Freude mit Ihrem Solarlux Produkt,
          <br />
          Ihr Solarlux Team
        </Text>

        {!loading &&
          showBonusFormButton &&
          null !== product &&
          0 !== product.length &&
          product.bonus.inCountryAvailable &&
          !product.bonus.isExpired && (
            <div style={{ marginBottom: '1rem' }}>
              <Button
                onClick={() => {
                  const form = document.getElementById('form');
                  form.scrollIntoView({ behavior: 'smooth', block: 'center' });
                }}
              >
                {product.bonus.showWarranty && (
                  <React.Fragment>Jetzt 10 Jahre Garantie sichern</React.Fragment>
                )}
                {!product.bonus.showWarranty && (
                  <React.Fragment>Jetzt Reinigungsset sichern</React.Fragment>
                )}
              </Button>
            </div>
          )}
      </div>

      <div
        className={classNames(styles.dynamicContent, {
          [styles.pushSpinner]: loading && null === product,
        })}
      >
        {loading && null === product && <Spinner />}
        {!loading && null !== product && 0 !== product.length && (
          <div>
            <Box>
              <Headline h2>Ihre Produkte</Headline>
              {product.positions.length > 0 && (
                <OrderPositions pid={pid} positions={product.positions} />
              )}
            </Box>
            <div className={styles.p1} />

            <div className={styles.row}>
              <div className={styles.column50}>
                <Box>
                  <Headline h2>Produkt-Details</Headline>
                </Box>
                <div className={styles.p1}>
                  <Text>
                    Erhalten Sie einen Überblick der Auftragsinformationen Ihres individuellen
                    Solarlux Produktes:
                    <br />
                    {product.text}
                  </Text>

                  {product.positions.filter((position) =>
                    ['Ecoline S', 'Highline S'].includes(position.title)
                  ).length > 0 && (
                    <Text>
                      Bei neuen Türelementen kann es vorkommen, dass die Beweglichkeit der
                      Schiebeflügel noch nicht optimal ist.
                      <br />
                      Erst bei montierten Anlagen können sich die Dichtungen richtig anpassen,
                      welches nach einiger Zeit zu leichtgängigeren Schiebeflügeln führt.
                    </Text>
                  )}

                  <ProductAttributes attributes={product.attributes} />
                </div>
              </div>
              <div className={styles.column50}>
                <Box>
                  <Headline h2>Produkt-Abbildung</Headline>
                </Box>
                <div className={styles.p1}>
                  <Text>
                    Die folgende Abbildung zeigt Ihr persönliches Solarlux Produkt. Hier sind die
                    einzelnen Elemente und Details dargestellt, die Ihr gesamter Auftrag umfasst.
                  </Text>
                  <ProductCanvas pid={pid} />
                </div>
              </div>
            </div>

            {product.showPleatsOrderForm && (
              <React.Fragment>
                <Box>
                  <Headline h2>Vorteile des Solarlux Plissees</Headline>
                </Box>
                <div className={styles.row}>
                  <div className={styles.column50}>
                    <div className={styles.p1}>
                      <Text>
                        Unser Solarlux Plissee bietet jede Menge Vorteile. Welche das sind, stellen
                        wir Ihnen im Folgenden und im Video vor:
                      </Text>
                      <ul className={styles.list}>
                        <li>einfache Montage</li>
                        <li>Montage auch an Schiebeflügeln möglich</li>
                        <li>Plissee bis zu 2 m breit und 3 m hoch</li>
                        <li>vollständiger Blickschutz durch abgerundete Leisten</li>
                        <li>nachspannende Führungsseile für eine lange Lebensdauer</li>
                        <li>praktisches Handling</li>
                        <li>Teleskopstab optional erhältlich</li>
                        <li>wahlweise zweiteilig</li>
                        <li>Sonnen-, Sicht-, Wärme- und Schallschutz</li>
                      </ul>
                    </div>
                  </div>
                  <div className={styles.column50}>
                    <div className={styles.p1}>
                      <iframe
                        width='100%'
                        height='480px'
                        src='https://www.youtube-nocookie.com/embed/pRJHUDoPAKs'
                        title='YouTube video player'
                        frameBorder='0'
                        allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                        allowFullScreen
                      />
                    </div>
                  </div>
                </div>

                <div className={styles.orderHint} style={{ marginBottom: '2.5rem' }}>
                  <div className={styles.orderHintIcon}>
                    <SVG src={pleatsIcon} />
                  </div>
                  <div className={styles.orderHintContent}>
                    <Headline h3>Jetzt Angebot für ein Plissee anfordern!</Headline>
                    <Text>
                      Stellen Sie hier ganz individuell Ihre Anforderungen für jedes gewünschte
                      Plissee zusammen und fordern Sie ein Angebot für jedes Ihrer Glaselemente an.
                    </Text>
                    <NavLink to={`/order?pid=${pid}`}>
                      <button>Angebot anfordern</button>
                    </NavLink>
                  </div>
                </div>
              </React.Fragment>
            )}

            <Box>
              <Headline h2>Downloads</Headline>
            </Box>
            <div className={`${styles.pt1} ${styles.plr1} ${styles.content}`}>
              <Text>
                In den folgenden Dokumenten erhalten Sie umfangreiche Informationen und Tipps zur
                Bedienung, Reinigung und Pflege Ihres Solarlux Produkts. Sie können sich diese
                einfach als PDF-Dateien herunterladen.
              </Text>
            </div>
            <div className={`${styles.pb1} ${styles.plr1} ${styles.downloads} ${styles.content}`}>
              <ProductDocuments pid={pid} />
            </div>

            {product.bonus.inCountryAvailable && (
              <React.Fragment>
                <div className={styles.special}>
                  <Box>
                    <Headline h2>
                      {product.bonus.showWarranty && (
                        <React.Fragment>Jetzt 10 Jahre Herstellergarantie sichern</React.Fragment>
                      )}
                      {!product.bonus.showWarranty && (
                        <React.Fragment>Jetzt Reinigungs- und Pflegeset sichern</React.Fragment>
                      )}
                    </Headline>
                  </Box>
                </div>
                <div id='form' className={`${styles.p1} ${styles.content}`}>
                  <Text>
                    {product.bonus.showWarranty && (
                      <React.Fragment>
                        {!product.isDirectOrder && (
                          <React.Fragment>
                            Wenn Sie Ihr Solarlux Produkt bei einem unserer über 80 zertifizierten
                            Quality Partnern erworben haben, bieten wir Ihnen hier die Möglichkeit,
                            sich für eine 10-jährige Herstellergarantie zu registrieren.
                          </React.Fragment>
                        )}
                        {product.isDirectOrder && (
                          <React.Fragment>
                            Wir bieten Ihnen hier die Möglichkeit, sich für eine 10-jährige
                            Herstellergarantie zur registrieren.
                          </React.Fragment>
                        )}
                      </React.Fragment>
                    )}
                    {!product.bonus.showWarranty && (
                      <React.Fragment>
                        Mit nur wenigen Klicks können Sie sich ein Solarlux Reinigungs- und
                        Pflegeset sichern und nach Hause bestellen. Selbstverständlich entstehen für
                        Sie keine zusätzlichen Kosten.
                      </React.Fragment>
                    )}
                    <React.Fragment>
                      {' '}
                      Diese Aktion {`${product.bonus.isExpired ? 'endete' : 'endet'}`} am{' '}
                      <b>{product.bonus.showUntil}</b>.
                    </React.Fragment>
                  </Text>

                  <BonusForm
                    showWarranty={product.bonus.showWarranty}
                    expired={product.bonus.isExpired}
                    orderNumber={product.orderId}
                    pid={pid}
                    dealer={product.partner.name}
                    statusReceivedCallback={(bonusReceived) => {
                      setShowBonusFormButton(bonusReceived);
                    }}
                  />
                </div>
              </React.Fragment>
            )}

            <Box>
              <Headline h2>
                {!product.isDirectOrder && <React.Fragment>Ausführender Händler</React.Fragment>}
                {product.isDirectOrder && <React.Fragment>Ihr Ansprechpartner</React.Fragment>}
              </Headline>
            </Box>
            <div className={`${styles.p1} ${styles.content}`}>
              <Text>
                Haben Sie weitere Fragen, die auf dieser Plattform nicht beantwortet werden? Möchten
                Sie Kontakt zu Ihrem
                {!product.isDirectOrder && <React.Fragment> Fachhändler </React.Fragment>}
                {product.isDirectOrder && <React.Fragment> Fachplaner </React.Fragment>}
                aufnehmen? Dieser steht Ihnen gerne zur Verfügung:
                <br />
                <br />
                <b>
                  {product.partner.salutation} {product.partner.name}
                </b>
                {product.partner.street.length !== 0 && (
                  <>
                    <br />
                    {product.partner.street}
                  </>
                )}
                {product.partner.address.length !== 0 && (
                  <>
                    <br />
                    {product.partner.address}
                  </>
                )}
                {product.partner.mail.length !== 0 && (
                  <>
                    <br />
                    {product.partner.mail}
                  </>
                )}
                <br />T {product.partner.phone}
                <br /> Auftragsnummer: {product.orderId}
              </Text>
            </div>

            <Box>
              <Headline h2>Service Hotline</Headline>
            </Box>
            <div className={`${styles.p1} ${styles.content}`}>
              <Text>
                Wenn Sie Ihr Serviceanliegen persönlich mit einem Mitarbeiter besprechen möchten,
                rufen Sie uns gerne über unsere Servicehotline an.
                <br />
                <br />
                <a href='tel:+49 5422 9271 777'>+49 5422 9271 777</a>
              </Text>
            </div>

            <Box>
              <Headline h2>Weiterführende Informationen</Headline>
              <div className={styles.moreInformation}>
                <Button
                  onClick={() => {
                    window.open('https://my.solarlux.com/apps/ersatzteile/?s=Reinigung', '_blank');
                  }}
                >
                  Reiniger & Pflegezubehör kaufen
                </Button>
                <Button
                  onClick={() => {
                    window.open('https://my.solarlux.com/apps/ersatzteile/', '_blank');
                  }}
                >
                  Solarlux Online-Shop
                </Button>
                <Button
                  onClick={() => {
                    window.open('https://solarlux.com/de-de/newsletter/anmeldung.html', '_blank');
                  }}
                >
                  Newsletter Anmeldung
                </Button>
              </div>
            </Box>

            <div className={`${styles.p1} ${styles.content}`}>
              <Text>
                <a
                  href='https://my.solarlux.com/apps/service/cases/add/'
                  target='_blank'
                  rel='noreferrer'
                >
                  Service-Fall anlegen – nur für Fachhändler
                </a>
              </Text>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Info;
